<template>
  <div style="padding: 14px;">
    <el-card>
      <div slot="header">单位信息</div>
      <div style="display: flex;flex-direction: column">
        <div class="item_container">
          <div class="item_left">单位名称：</div>
          <div class="item_right">
            {{ unit.name }}
          </div>
        </div>
        <div class="item_container">
          <div class="item_left">城市：</div>
          <div class="item_right">
            {{ unit.city }}
          </div>
        </div>
        <div class="item_container">
          <div class="item_left">单位地址：</div>
          <div class="item_right">
            {{ unit.address }}
          </div>
        </div>

        <div class="item_container">
          <div class="item_left">邮箱：</div>
          <div class="item_right">
            {{ unit.email }}
          </div>
        </div>

        <div class="item_container">
          <div class="item_left">领队：</div>
          <div class="item_right">
            {{ unit.leader }}
          </div>
        </div>

        <div class="item_container">
          <div class="item_left">领队手机号：</div>
          <div class="item_right">
            {{ unit.leaderPhone }}
          </div>
        </div>

        <div class="item_container">
          <div class="item_left">副领队：</div>
          <div class="item_right">
            {{ unit.secondLeader }}
          </div>
        </div>

        <div class="item_container">
          <div class="item_left">副领队手机号：</div>
          <div class="item_right">
            {{ unit.secondLeaderPhone }}
          </div>
        </div>

      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "UnitDetail",
  props: {
    unit: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped>
.item_container {
  display: flex;
  flex-wrap: nowrap;
  padding: 7px 0;
}

.item_left {
  width: 150px;
  font-weight: bold;
  color: #333333;
}

.item_right {
  flex: 1;
  color: #666666;
}
</style>