<template>
  <div style="padding: 14px;">
    <el-card>
      <div slot="header">
        团体赛
      </div>
      <template v-if="teamCompetitionList.length > 0">
        <el-card :key="item.id" v-for="item in teamCompetitionList" style="margin-bottom: 14px;">

          <div class="item_container">
            <div class="item_left">序号：</div>
            <div class="item_right">{{item.sortNo}}</div>
          </div>

          <div class="item_container">
            <div class="item_left">队伍名称：</div>
            <div class="item_right">{{item.name}}</div>
          </div>

          <div class="item_container">
            <div class="item_left">团队成员：</div>
            <div v-if="item.memberList.length > 0" class="item_right">
              <div :key="member.id" v-for="member in item.memberList">
                <span style="color: #444444;">姓名：</span><span style="padding: 0 28px 0 14px;color: #777777">{{member.memberName}}</span>
                <span style="padding-left: 14px;color: #444444;">性别：</span>
                <span style="padding: 0 28px 0 14px;color: #777777" v-if="member.memberSex === 1">男</span>
                <span style="padding: 0 28px 0 14px;color: #777777" v-else-if="member.memberSex === 2">女</span>
                <span style="color: #444444;">身份证号：</span><span style="padding: 0 28px 0 14px;color: #777777">{{member.memberSfzh}}</span>
              </div>
            </div>
            <div v-else>--</div>
          </div>

          <div class="item_container">
            <div class="item_left">参与赛项：</div>
            <div class="item_right">
              <span v-if="item.competitionType === 1">团体接力飞行赛（4人）</span>
              <span v-else-if="item.competitionType === 2">空中格斗赛（2-3人）</span>
              <span v-else-if="item.competitionType === 3">空中足球赛（2-3人）</span>
              <span v-else-if="item.competitionType === 4">蜂群舞蹈编程赛（2人）</span>
              <span v-else-if="item.competitionType === 5">创意图形编程赛（2-3人）</span>
              <span v-else-if="item.competitionType === 6">“蜂鸟行动”赛（2-3人）</span>
              <span v-else-if="item.competitionType === 7">团体察打赛（2-3人）</span>
              <span v-else-if="item.competitionType === 13">动力飞行器创意赛（1-3人）</span>
            </div>
          </div>

          <div class="item_container">
            <div class="item_left">指导老师：</div>
            <div class="item_right">{{item.instructor}}</div>
          </div>

        </el-card>
      </template>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "TeamCompetitionDetail",
  props: {
    teamCompetitionList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.item_container {
  display: flex;
  flex-wrap: nowrap;
  padding: 7px 0;
}

.item_left {
  width: 150px;
  font-weight: bold;
  color: #333333;
}

.item_right {
  flex: 1;
  color: #666666;
}
</style>