<template>
  <div>
    <unit-detail v-if="Object.keys(detailModel.unit).length > 0" :unit="detailModel.unit"></unit-detail>
    <personal-competition-detail v-if="detailModel.personalCompetitionList.length > 0"
                                 :personal-competition-list="detailModel.personalCompetitionList"></personal-competition-detail>
    <team-competition-detail v-if="detailModel.teamCompetitionList.length > 0"
                             :team-competition-list="detailModel.teamCompetitionList"></team-competition-detail>
<!--    <restaurant-order-detail v-if="detailModel.restaurantOrderList"-->
<!--                             :restaurant-order-list="detailModel.restaurantOrderList"></restaurant-order-detail>-->
    <attachment-detail v-if="detailModel.attachmentUrlList.length > 0"
                       :attachment-url-list="detailModel.attachmentUrlList"></attachment-detail>
  </div>
</template>

<script>
import {get} from "@/api/httpHelp";
import UnitDetail from "@/views/system/detail/UnitDetail";
import PersonalCompetitionDetail from "@/views/system/detail/PersonalCompetitionDetail";
import TeamCompetitionDetail from "@/views/system/detail/TeamCompetitionDetail";
import AttachmentDetail from "@/views/system/detail/AttachmentDetail";

export default {
  name: "SignUpDetail",
  components: {
    UnitDetail,
    PersonalCompetitionDetail,
    TeamCompetitionDetail,
    AttachmentDetail
  },
  props: {
    unitId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      detailModel: {
        unit: {},
        personalCompetitionList: [],
        teamCompetitionList: [],
        restaurantOrderList: [],
        attachmentUrlList: []
      },
      url: {
        getDetail: 'detailSignUp'
      }
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      if (this.unitId === '') {
        this.$message.error("参数错误")
        return
      }
      get(this.url.getDetail, {
        unitId: this.unitId
      }).then(res => {
        console.log("detail Res", res)
        if (res != null) {
          this.detailModel = res.result
        } else {
          this.detailModel = {}
        }
      }).finally(() => {
      })
    }
  }
}
</script>

<style scoped>

</style>