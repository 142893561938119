<template>
  <div v-if="itemList.length > 0 && okFlag" style="padding: 14px;">
    <el-card>
      <div slot="header">附件</div>
      <el-row :gutter="14">
        <el-col :span="6" :key="index" v-for="(item, index) in itemList">
          <div v-if="item.type === 'image'" style="margin-bottom: 14px;">
            <el-image
                style="width: 200px; height: 200px;border-radius: 10px;box-shadow: 0 2px 12px 0 rgba(0,0,0,0.5);"
                :src="item.url"
                fit="cover"
                lazy
                :preview-src-list="imageList">
              <div style="display: flex;align-items: center;justify-content: center;height: 100%;width: 100%;" slot="placeholder">
                <em style="font-size: 25px;" class="el-icon-loading"/>
              </div>
            </el-image>
          </div>
          <div v-else
               style="width: 200px; height: 200px;display: flex;align-items: center;justify-content: center;margin-bottom: 14px;box-shadow: 0 2px 12px 0 rgba(0,0,0,0.5);border-radius: 10px;">
            <div style="padding: 14px;">
              <div style="text-align: center">
                <img alt="下载附件" :src="attachmentImage" style="width: 50px;height: 50px;display: inline-block"/>
              </div>
              <div style="margin-top: 14px;">
                <el-button @click="downLoad(item.saveUrl)" type="primary" size="mini" icon="el-icon-download">下载
                </el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {baseUrl, downFile} from "@/api/httpHelp";
import {Toast} from "vant";

export default {
  name: "AttachmentDetail",
  props: {
    attachmentUrlList: {
      type: Array,
      required: true
    },

  },
  data() {
    return {
      okFlag: false,
      attachmentImage: require('@/assets/images/attachment.png'),
      itemList: [],
      imageList: [],
      url: {
        downLoad: 'upload/downLoad'
      }
    }
  },
  watch: {
    attachmentUrlList: {
      immediate: true,
      handler(val) {
        if (val && val.length > 0) {
          for (const element of val) {
            let item = {
              saveUrl: element,
              url: baseUrl + element
            }
            if (/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(element)) {
              item.type = 'image'
              this.imageList.push(baseUrl + element)
            }
            this.itemList.push(item)
          }
          this.okFlag = true
        }
      }
    }
  },
  methods: {
    downLoad(saveUrl) {
      let toastLoad = Toast.loading({
        duration: 0,
        message: '下载中...',
        forbidClick: true
      })
      downFile(this.url.downLoad, {
        saveUrl: saveUrl
      }).then((data) => {
        console.log(data)
        if(data.status === 200 && data.request.responseType === 'blob'){
          console.log("下载")
          let url = window.URL.createObjectURL(new Blob([data.data]))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          let fileName = data.headers['content-disposition']
          let startStr = 'attachment;fileName='
          let targetFileName = fileName.replace(startStr, '')
          link.setAttribute('download', targetFileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link) //下载完成移除元素
          window.URL.revokeObjectURL(url) //释放掉blob对象
        }
      }).finally(() => {
        toastLoad.clear()
      })
    }
  }
}
</script>

<style scoped>

</style>