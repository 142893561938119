<template>
  <div style="padding: 20px;background-color: #F2f2f5;height: 100%;">

    <el-card style="height: 100%;box-sizing: border-box;">
      <template slot="header">
        <div style="text-align: center;font-size: 1.2em;line-height: 24px;font-weight: 500;">已报名列表</div>
      </template>
      <div style="padding-bottom: 14px;">
        <el-button type="primary" @click="downExcel">下载</el-button>
      </div>

      <el-table
          v-loading="loading"
          :border="true"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          :data="tableData"
      >
        <el-table-column
            type="index"
            label="序号"
            width="80"
            align="center"
        />

        <el-table-column
            label="比赛类型"
            prop="category"
            width="150"
            align="center"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.category === 1"
                    type="primary"
            >
              小学组
            </el-tag>
            <el-tag v-if="scope.row.category === 2"
                    type="primary"
            >
              初中组
            </el-tag>
            <el-tag v-if="scope.row.category === 3"
                    type="primary"
            >
              高中组
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column
            label="单位名称"
            prop="name"
        />

        <el-table-column
            label="领队"
            width="150"
            prop="leader"
        />

        <el-table-column
            label="领队手机号"
            width="150"
            prop="leaderPhone"
        />

        <el-table-column
            label="副领队"
            width="150"
            prop="secondLeader"
        />

        <el-table-column
            label="副领队手机号"
            width="150"
            prop="secondLeaderPhone"
        />

        <el-table-column
            label="联系邮箱"
            width="150"
            prop="email"
        />

        <el-table-column
            label="单位地址"
            prop="address"
        />
        <el-table-column
            align="center"
            width="150"
        >
          <template slot="header">
            操作
          </template>
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="handlerDetail(scope.row)">详情
            </el-button>
            <!--            <el-button-->
            <!--                size="mini"-->
            <!--                @click="exportExcel(scope.row)">导出-->
            <!--            </el-button>-->
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 14px;float: right;margin-bottom: 20px;">
        <el-pagination
            background
            layout="prev, pager, next,total"
            :total="page.total"
            :current-page="page.pageNo"
            :page-size="page.pageSize"
            @current-change="handleCurrentChange"
        ></el-pagination>
      </div>

    </el-card>
    <el-drawer
        :title="drawerTitle"
        size="1000px"
        :visible.sync="drawerVisible"
        :direction="drawerDirection"
        :before-close="handleDrawerClose">
      <sign-up-detail v-if="unitId !== ''" :unitId="unitId"></sign-up-detail>
    </el-drawer>
  </div>
</template>

<script>
import {downFile, get} from '@/api/httpHelp'
import SignUpDetail from "@/views/system/detail/SignUpDetail";
import {Toast} from "vant";

export default {
  name: "UnitList",
  components: {
    SignUpDetail
  },
  data() {
    return {
      loading: true,
      tableData: [],  // 表数据
      border: true,   // 显示
      page: {
        total: 0,
        pageNo: 1,
        pageSize: 10
      },
      drawerVisible: false,
      drawerTitle: '详情',
      drawerDirection: 'ltr',
      unitId: '',
      url: {
        page: 'unit/page',
        exportXls: "export/exportXls"
      },
      detailModel: {}
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    downExcel(){
      let toastLoad = Toast.loading({
        duration: 0,
        message: '下载中...',
        forbidClick: true
      })
      downFile(this.url.exportXls, {}).then((data) => {
        console.log(data)
        if(data.status === 200 && data.request.responseType === 'blob'){
          console.log("下载")
          let url = window.URL.createObjectURL(new Blob([data.data]))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          let fileName = data.headers['content-disposition']
          let startStr = 'attachment;fileName='
          let targetFileName = fileName.replace(startStr, '')
          link.setAttribute('download', targetFileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link) //下载完成移除元素
          window.URL.revokeObjectURL(url) //释放掉blob对象
        }
      }).finally(() => {
        toastLoad.clear()
      })
    },
    handleCurrentChange(pageNo){
      console.log("change page no", pageNo)
      this.page.pageNo = pageNo
      this.loadData()
    },
    handleDrawerClose(done) {
      this.drawerVisible = false
      this.unitId = ''
      done()
    },
    handlerDetail(row) {
      console.log("row", row)
      if (row && row.id !== '') {
        this.unitId = row.id
        this.drawerVisible = true
      }
    },
    exportExcel(record) {
      console.log("record", record)
    },
    loadData() {
      this.loading = true
      let params = {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize
      }
      get(this.url.page, params).then(res => {
        if (res != null) {
          this.tableData = res.result.records
          this.page.pageNo = parseInt(res.result.current)
          this.page.pageSize = parseInt(res.result.size)
          this.page.total = parseInt(res.result.total)
        } else {
          if (this.page.pageNo === 1) {
            this.tableData = []
          }
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>