<template>
  <div style="padding: 14px;">
    <el-card>
      <div slot="header">
        个人赛
      </div>
      <template v-if="personalCompetitionList.length > 0">
        <el-card :key="item.id" v-for="item in personalCompetitionList" style="margin-bottom: 14px;">
          <div class="item_container">
            <div class="item_left">
              序号
            </div>
            <div class="item_right">
              {{ item.sortNo }}
            </div>
          </div>

          <div class="item_container">
            <div class="item_left">
              姓名:
            </div>
            <div class="item_right">
              {{ item.name }}
            </div>
          </div>

          <div class="item_container">
            <div class="item_left">
              性别:
            </div>
            <div class="item_right">
              <span v-if="item.sex === 1">男</span>
              <span v-else-if="item.sex === 2">女</span>
            </div>
          </div>
          <div class="item_container">
            <div class="item_left">
              身份证号:
            </div>
            <div class="item_right">
              {{ item.sfzh }}
            </div>
          </div>
          <div class="item_container">
            <div class="item_left">
              参与赛项:
            </div>
            <div class="item_right">
              <span v-if="item.competitionType === 1">个人飞行赛</span>
              <span v-else-if="item.competitionType === 2">物流搬运赛</span>
              <span v-else-if="item.competitionType === 3">个人空中射击赛</span>
              <span v-else-if="item.competitionType === 4">编程挑战赛</span>
              <span v-else-if="item.competitionType === 5">越障打击赛</span>
              <span v-else-if="item.competitionType === 6">编程空中搜寻赛</span>
              <span v-else-if="item.competitionType === 7">“雷霆飞途”赛</span>
              <span v-else-if="item.competitionType === 8">空中侦察赛(第一视角)</span>
              <span v-else-if="item.competitionType === 9">个人侦察赛</span>
              <span v-else-if="item.competitionType === 10">模拟飞行紧急迫降挑战赛</span>
              <span v-else-if="item.competitionType === 11">模拟飞行大飞机转场挑战赛</span>
              <span v-else-if="item.competitionType === 12">模拟飞行个人竞速赛</span>

              <span v-else-if="item.competitionType === 14">无人机应用场景创意编程赛</span>
            </div>
          </div>

          <div class="item_container">
            <div class="item_left">
              指导老师:
            </div>
            <div class="item_right">
              {{ item.instructor }}
            </div>
          </div>

        </el-card>
      </template>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "PersonalCompetitionDetail",
  props: {
    personalCompetitionList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.item_container {
  display: flex;
  flex-wrap: nowrap;
  padding: 7px 0;
}

.item_left {
  width: 150px;
  font-weight: bold;
  color: #333333;
}

.item_right {
  flex: 1;
  color: #666666;
}
</style>